export const useRouteHref = () => {
  const route = useRoute()

  const getRouteHref = (
    {
      domain,
      queryParams = [],
      customPath,
    }: {
      domain?: string
      queryParams?: MaybeRef<{ key: string, value: string | number }[]>
      customPath?: string
    }) => {
    if (!domain) {
      return ''
    }
    let schemeDomain = domain, url: URL
    if (!schemeDomain.match(new RegExp('^https?://'))) {
      schemeDomain = `https://${schemeDomain}`
    }
    try {
      url = new URL(customPath ?? route.path, schemeDomain)
    }
    catch (e) {
      console.error(e)
      return ''
    }
    toValue(queryParams).forEach(({ key, value }) => {
      url.searchParams.append(key, value.toString())
    })
    return url.href
  }

  return { getRouteHref }
}
