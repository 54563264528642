export const useSeoMetaLinksAlternate = (
  {
    queryParams,
    customPaths,
  }: {
    queryParams?: MaybeRef<{ key: string, value: string | number }[]>
    customPaths?: { locale: string, customPath: string }[]
  } = { queryParams: [], customPaths: undefined },
) => {
  const { getRouteHref } = useRouteHref()
  const { locales } = useI18n()

  const localesValue = toValue(locales)

  const getLink = ({ yscCode, domain, customPath }: { yscCode: string, domain?: string, customPath?: string }) => ({
    key: `rel-alternate-${yscCode}`, // key is needed to dedupe tag in specific pages (query canonical)
    rel: 'alternate',
    hreflang: yscCode,
    href: getRouteHref({ domain: domain, queryParams, customPath }),
  })

  let linkAlternates: { key: string, rel: string, hreflang: string, href: string }[] = []
  if (customPaths) {
    customPaths.forEach(({ locale, customPath }) => {
      const normalizedLocale = locale === 'pt' ? 'pt-pt' : locale
      const localeConfig = localesValue.find(({ yscCode }) => yscCode === normalizedLocale)
      if (localeConfig) {
        linkAlternates.push(getLink({ yscCode: localeConfig.yscCode, domain: localeConfig.domain, customPath }))
      }
    })
  }
  else {
    linkAlternates = localesValue.map(({ yscCode, domain }) => getLink({ yscCode, domain }))
  }

  const { origin, pathname, searchParams } = useRequestURL()
  const canonicalHref = new URL(`${origin}${pathname}`)
  if (queryParams) {
    toValue(queryParams).forEach(({ key }) => {
      if (searchParams.has(key)) {
        canonicalHref.searchParams.set(key, searchParams.get(key) as string)
      }
    })
  }

  useHead({
    link: [
      ...linkAlternates,
      { rel: 'canonical', href: canonicalHref.href },
    ],
    meta: linkAlternates.map(({ hreflang }) => getMetaProperty({ property: 'og:locale:alternate', content: hreflang })),
  })
}
